import React from 'react';
import { Container, Row, Col } from 'react-grid-system';
import { useIntl, FormattedMessage } from 'gatsby-plugin-intl';

import Layout from '../../../components/Layouts/layout';
import SEO from '../../../components/seo';
import SubPageNav from '../../../components/SubPageNav/SubPageNav';
import SubPageBottomNav from '../../../components/SubPageBottomNav/SubPageBottomNav';
import MagnifyingGlass from '../../../svgs/magnifyingGlassHome-greyBg.svg';

const HombuyersJourneyStep4Page = () => {
	const intl = useIntl();
	return (
		<Layout
			view='buyer'
			breadcrumb={{
				text: <FormattedMessage id='buyerNav.dashboard' />,
				path: '/new-home-buyer/dashboard/'
			}}
			header={<FormattedMessage id='buyerNav.steps' />}
		>
			<SEO lang={intl.locale} title={intl.formatMessage({ id: 'buyerNav.step4' })} />
			<Container>
				<Row>
					<Col>
						<SubPageNav
							links={[
								{
									text: <FormattedMessage id='buyerNav.step1' />,
									path: '/new-home-buyer/steps-in-home-buyers-journey/step-1/'
								},
								{
									text: <FormattedMessage id='buyerNav.step2' />,
									path: '/new-home-buyer/steps-in-home-buyers-journey/step-2/'
								},
								{
									text: <FormattedMessage id='buyerNav.step3' />,
									path: '/new-home-buyer/steps-in-home-buyers-journey/step-3/'
								},
								{
									text: <FormattedMessage id='buyerNav.step4' />,
									path: '/new-home-buyer/steps-in-home-buyers-journey/step-4/',
									active: true
								},
								{
									text: <FormattedMessage id='buyerNav.step5' />,
									path: '/new-home-buyer/steps-in-home-buyers-journey/step-5/'
								},
								{
									text: <FormattedMessage id='buyerNav.step6' />,
									path: '/new-home-buyer/steps-in-home-buyers-journey/step-6/'
								}
							]}
						/>
					</Col>
				</Row>

				<Row className='pageRow' align='center'>
					<Col md={8}>
						<h2>
							<FormattedMessage id='nav.step' /> 4{' '}
							<span className='thin'>
								{' '}
								<FormattedMessage id='buyerNav.step4' />{' '}
							</span>
						</h2>
						<p>
							<FormattedMessage id='homebuyersStep4.contentA' />
						</p>

						<p>
							<FormattedMessage id='homebuyersStep4.contentAi' />{' '}
							<a
								href='https://www.tarion.com/homeowners/pre-delivery-inspection'
								aria-label={intl.formatMessage({
									id: 'homebuyersStep4.contentAii'
								})}
							>
								<FormattedMessage id='homebuyersStep4.contentAii' />
							</a>{' '}
							<FormattedMessage id='homebuyersStep4.contentAiii' />
						</p>

						<h3 className='sm'>
							{' '}
							<FormattedMessage id='homebuyersStep4.headerA' />{' '}
						</h3>
						<p>
							<FormattedMessage id='homebuyersStep4.contentB' />
							<br />
							<FormattedMessage id='homebuyersStep4.contentC' />
						</p>
					</Col>

					<Col md={4}>
						<MagnifyingGlass />
					</Col>
				</Row>

				<Row>
					<Col md={12}>
						<SubPageBottomNav
							prevLink={{
								text: <FormattedMessage id='buyerNav.step3' />,
								path: '/new-home-buyer/steps-in-home-buyers-journey/step-3/'
							}}
							nextLink={{
								text: <FormattedMessage id='buyerNav.step5' />,
								path: '/new-home-buyer/steps-in-home-buyers-journey/step-5/'
							}}
						/>
					</Col>
				</Row>
			</Container>
		</Layout>
	);
};

export default HombuyersJourneyStep4Page;
